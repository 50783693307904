/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Article, Hero, I18Link, Quote } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import headerImage from "../../images/Business_Process_Modelling.jpg";
import commaimg from "../../images/Quotation_mark.png";
import uparrow from "../../images/top-button.svg";
import valueanalyseIcon from "../../images/Value_hound_value_analysis.png";
import bussiness_Model_section from "../../images/business_modelling.webp";
import attribute_img_section from "../../images/attribution_analysis.webp";
import variance_img_section from "../../images/variance_analysis.webp";
import sensitivity_img_section from "../../images/sensitivity_analysis.webp";
import bussinessModelIcon from "../../images/Business modelling.png";
import leftIcon from "../../images/Left Button.svg";
const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { headerImage },
      srcSet: headerImage,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1440,
  height: 720,
};
const bussinessModelsection_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { bussiness_Model_section },
      srcSet: bussiness_Model_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const sensitivity_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { sensitivity_img_section },
      srcSet: sensitivity_img_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const attribute_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { attribute_img_section },
      srcSet: attribute_img_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const variance_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { variance_img_section },
      srcSet: variance_img_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const comma = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 600px) 600px, 100vw",
      src: { commaimg },
      srcSet: commaimg,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 600,
  height: 600,
};
const persondetails = {
  company: null,
  country: "Australia and New Zealand",
  image: comma,
  jobTitle: "Maintenance Manager",
  link: "/people/skipp-williamson-et",
  linkedin: "https://www.linkedin.com/in/skippwilliamson/",
  name: "Mining Services",
  quote:
    "WiredUp enabled meaningful management meetings. It allowed each area manager to talk about their successes, challenges, disappointments and emerging issues, and empowered them to ask our project manager what things we needed help with.",
  sector: "Infrastructure",
};
function clikinguparrow() {
  window.scrollTo({ top: 0, behavior: "smooth" });
  return;
}
const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: {},
    graphCmsSectorPage: sectorPage,
  },
}) => {
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={
        "WiredUp, Partners in Performance, Business Transformation, Initiative Management, Project Management, Emissions Management, Digital Transformation"
      }
      title={" Business Process Modelling | Partners in Performance"}
      description={
        "WiredUp is a proven change management software suite that makes managing successful programs simple, easy and secure. "
      }
    >
      <Hero
        title="WiredUp"
        subheading="Business process modelling"
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <div className="py-60px lg:grid-cols-2 grid-cols-1 p-10px page-grid row md:w-mobile-6-cols w-auto m-auto">
        <a
          href="#Business_Modelling"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={bussinessModelIcon}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="m-auto">
                <h4 className="h4">Business modelling</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Model your organisation through value driver trees to find
                bottlenecks, inefficient operations and identify value
                generators
              </p>
            </div>
          </div>
        </a>
        <a
          href="#Value_Analysis"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={valueanalyseIcon}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="m-auto">
                <h4 className="h4">Value analysis</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Discover how value creation changes with shifting KPI values by
                using sensitivity, attribution and variance analysis
              </p>
            </div>
          </div>
        </a>
      </div>
      <div id="Business_Modelling">
        <Article
          side="left"
          id={""}
          image={bussinessModelsection_img}
          alt={""}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Gain a deeper understanding of your organisation by mapping out key processes and linking KPIs with value driver trees (VDTs) for real-time performance measurements. WiredUp’s VDTs can manage hundreds of nodes driven by various KPIs to give you an up-to-date understanding of any bottlenecks, inefficient operations and key value generators, empowering you to improve performance across your organisation. ",
              heading: [],
            },
          ]}
          Articletitle="Business modelling"
          listing={[]}
          afterlisting=""
        />
      </div>
      <div id="Sensitivity_Analysis">
        <Article
          side="right"
          id={""}
          image={sensitivity_img}
          alt={""}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Use sensitivity analysis to improve decision-making across your organisation by calculating which input KPI’s have the biggest impact on each business process model and see how each lever is affected when variance is introduced.",
              heading: [],
            },
          ]}
          Articletitle="Sensitivity analysis"
          listing={[]}
          afterlisting=""
        />
      </div>
      <div id="Attribution_Analysis">
        <Article
          side="left"
          id={""}
          image={attribute_img}
          alt={""}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Identify performance and underperformance by comparing values across different KPIs to determine how fluctuations impact value drivers in your organisation. WiredUp’s attribution analysis allows you to compare control fields against analysis fields, giving you a better understanding of how business process model outcomes are affected by KPI variance.",
              heading: [],
            },
          ]}
          Articletitle="Attribution analysis"
          listing={[]}
          afterlisting=""
        />
      </div>
      <div id="Value_Analysis">
        <Article
          side="right"
          id={""}
          image={variance_img}
          alt={""}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Discover performance across your business through variance analysis. Select individual percentage or absolute variance values per KPI and calculate how they impact the business process model outcome. This allows you to see the difference between planned and actual values, highlighting any areas that require attention or improvement.",
              heading: [],
            },
          ]}
          Articletitle="Variance analysis"
          listing={[]}
          afterlisting=""
        />
      </div>
      <p className="col-span-12 col-start-2 text-center p-20px bg-light-blue">
        Find out how WiredUp can help your organisation make a lasting impact{" "}
        <b>
          <br></br>
          <center>
            <a href={"/en/wiredup-enquiry/"} className="text-dark-blue">
              Schedule a demo
            </a>
          </center>
        </b>
      </p>
      <section className="py-section page-grid max-width z-10 gap-y-20px">
        <I18Link
          to={"/wiredup"}
          className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6"
        >
          <img
            src={leftIcon}
            alt="Left"
            className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
          />
          Back to WiredUp
        </I18Link>
      </section>
      <br></br>
      <button id="scrollToTopBtn" onClick={clikinguparrow}>
        <img src={uparrow} className="uparrow_wiredup"></img>
      </button>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
